module.exports = {
  CANCELLED: 'cancelled',
  OPEN: 'open',
  ACCEPTED: 'accepted',
  ACCEPT: 'accept',
  CONTACT: 'contact',
  REJECTED: 'rejected',
  REJECT: 'reject',
  DONE: 'done'
};
