<template>
  <v-container>
    <v-card class="content-card-padding">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <download-excel
          :data="searchResult"
          :fields="exportDataFields"
          :meta="[]"
          name="download.xls"
        >
          <app-button
            :disabled="searchResult.length < 1"
            secondary
          >
            Export Results
          </app-button>
        </download-excel>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="transactions"
        :search="search"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :no-data-text="noData"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Transactions per page:',
          itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
        }"
      >
        <template
          v-if="isLoading.transactions"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #item="{ item }"
        >
          <tr>
            <td>{{ item.creationDate | formatDate }}</td>
            <td>{{ item.providerName }}</td>
            <td>{{ item.offerTitle }}</td>
            <td>{{ item.projectName }}</td>
            <td>{{ item.projectCreator }}</td>
            <td>{{ item.amount }}</td>
            <td class="status">
              {{ formatStatus(item.status) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { TableSkeletonLoader } from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import REQUEST_STATUS from '@kickbox/common-util/constants/request-status';
import transactionService from '@/services/transactionService';

export default {
  components: {
    TableSkeletonLoader
  },
  data() {
    return {
      exportDataFields: {
        'Creation Date': 'creationDate',
        'Provider Name': 'providerName',
        'Offer Title': 'offerTitle',
        'Project Name': 'projectName',
        'Project Creator': 'projectCreator',
        Amount: 'amount',
        Status: 'status',

      },
      search: '',
      headers: [
        { text: 'Creation Date', value: 'creationDate', align: 'left', width: 140 },
        { text: 'Provider Name', value: 'providerName', align: 'left', width: 150 },
        { text: 'Offer Title', value: 'offerTitle', align: 'left', width: 200 },
        { text: 'Project Name', value: 'projectName', align: 'left', width: 200 },
        { text: 'Project Creator', value: 'projectCreator', align: 'left', width: 150 },
        { text: 'Amount', value: 'amount', align: 'left', width: 110 },
        { text: 'Status', value: 'status', align: 'left' }
      ],
    };
  },
  computed: {
    ...mapGetters([
      'transactions',
      'company',
      'isLoading'
    ]),
    noData() {
      return this.isLoading.transactions ? 'loading...' : 'No transactions.';
    },
    searchResult() {
      return this.transactions.filter((t) => this.customFilter(null, this.search, t));
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async () => {
      if (!from.name) {
        await transactionService.getTransactions();
      }
    });
  },
  methods: {
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return moment(item.creationDate).format('MMM DD, YYYY').toLowerCase().includes(customSearch)
        || item.providerName.toLowerCase().includes(customSearch)
        || item.offerTitle.toLowerCase().includes(customSearch)
        || item.projectName.toLowerCase().includes(customSearch)
        || item.projectCreator.toLowerCase().includes(customSearch)
        || String(item.amount).includes(customSearch)
        || item.status.toLowerCase().includes(customSearch);
    },
    customSort(items, sortProp, isDesc) {
      items.sort((self, other) => {
        if (self[sortProp[0]] === other[sortProp[0]]) {
          return 0;
        }
        return self[sortProp[0]] < other[sortProp[0]] ? -1 : 1;
      });
      return isDesc[0] ? items.reverse() : items;
    },
    formatStatus(status) {
      if (status === REQUEST_STATUS.REJECTED) {
        return 'SP Rejected';
      }
      if (status === REQUEST_STATUS.CANCELLED) {
        return 'User Cancelled';
      }
      return status;
    }
  }
};
</script>

<style>
  .status {
    text-transform: capitalize;
  }
</style>
